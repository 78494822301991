@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;0,6..12,800;0,6..12,900;0,6..12,1000;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700;1,6..12,800;1,6..12,900;1,6..12,1000&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Nunito Sans', sans-serif;
}


body {
  background-color: black;
}

.background-container {
  height: 80vh;
  position: relative;
  z-index: 0;
}

.background-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.background-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.header p {
  color: white;
  font-size: 28px;
  padding: 20px;
  font-weight: 600;
}

.header {
  border-bottom: 0.05em solid #ffd100;
  height: 75px;
  display: flex;
  justify-content: space-between;
}

.nav-social {
  padding-right: 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.nav-social a {
  color: white;
  padding: 5px 15px;
  border: 1px solid silver;
  border-radius: 5px;
  margin: 0px 10px;
  text-decoration: none;
  transition: background-color ease-in-out 0.3s;
}

.nav-social a:hover {
  background-color: #ffd100;
  color: #363636;
  border: 1px solid #363636;
}

.nav-social i {
  padding-left: 5px;
}



.header p span {
  color: #ffd100;
}

.homeContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(80vh - 75px);
  padding-left: 50px;
  flex: 2;
}

.homeContent h1 {
  font-size: 58px;
  color: #e1e1e1;
  line-height: 1em;
}

.homeContent h4 {
  color: #ccc;
  font-weight: 600;
  padding-top: 20px;
  font-size: 20px;
}

.gradient-button {
  display: inline-block;
  padding: 10px 20px;
  text-decoration: none;
  font-size: 16px;
  color: #4a4b4c;
  border: none;
  border-radius: 20px;
  width: fit-content;
  border: #ffd100 1px solid;
  transition: color 0.5s ease;
  margin-top: 30px;
  font-size: 22px;
  font-weight: 600;
  background-image: linear-gradient(45deg, #ffd100, #faf8e6);
}

.gradient-button:hover {
  color: black;
  background-image: linear-gradient(45deg, #faf8e6, #ffd100);
}

.homeContainer {
  display: flex;
  justify-content: space-evenly;
}

.logoContainer {
  flex: 1;
  padding: 50px;
  height: calc(80vh - 75px);
  margin: auto;
  display: flex;
  align-items: center;
}

.logoContainer.new {
  flex: 1;
  padding: 50px;
  height: calc(80vh - 75px);
  margin: auto;
  display: flex;
  align-items: center;

}

.logoContainer img {
  width: 90%;
  height: 100%;
}

/**************justorder-features**********/

.features {
  color: white;
}

.justorder-features {
  color: white;
  width: 95%;
  margin: 50px auto;
}

.why-jo {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
}

.why-jo span {
  color: #ffd100;
}

.feature-card {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
}

.features-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.feature-card {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
}

.feature-card #lottie {
  width: 90x;
  height: 90px;
}

.feature-card.span #lottie {
  width: 60px;
  height: 60px;
}

.feature-card.span h3 {
  padding-top: 15px;
}

.feature-card h3 {
  margin-top: 10px;
  font-size: 1.5rem;
}

.feature-card p {
  margin-top: 10px;
  font-size: 1rem;
  color: #ccc;
}



.mid-main-container {
  display: flex;
  padding-top: 30px;
  align-items: center;
  justify-content: space-between;
}

.yt-wrapper {
  display: flex;
  justify-content: center;
  flex: 1;
}

.why-jo.left {
  color: white;
  text-align: left;
  padding-bottom: 30px;
}

.payment-section {
  width: 90%;
  margin: auto;
}

.gal-items {
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: space-between;
  flex-wrap: wrap;
}

.img-wrapper {
  height: 500px;
  width: 23%;
}

.img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.invite-section {
  width: 90%;
  margin: auto;
  padding: 50px 20px;
}

.invite-section img {
  transition: 0.5s;
}

.invite-section img:hover {
  transform: scale(1.05);
}

.footer {
  color: white;
  display: flex;
  width: 90%;
  margin: auto;
  padding: 0px 0px 50px 0px;
}

.footer-left {
  flex: 2;
  text-align: center;
  border: 1px solid silver;
  border-right: none;
  padding: 20px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 16px;
}

.footer-mid {
  flex: 8;
  display: flex;
  justify-content: space-between;
  border: 1px solid silver;
  padding: 20px;
  font-size: 16px;

}

.footer-right {
  display: flex;
  flex: 2;
  display: flex;
  justify-content: center;
  border: 1px solid silver;
  align-items: center;
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 16px;
}

.span {
  color: #ffd100;
}

.main-content-wrapper {
  flex: 1;
  height: 100%;
}

.footer-mid p a {
  font-weight: 800;
  text-decoration: none;
  color: white;
  transition: 0.2s;
  cursor: pointer;
}

.footer-mid p a:hover {
  color: #ffd100;
}

.footer-mid p i {
  padding-left: 5px;
}

.move-top:hover i {
  color: #ffd100;
  cursor: pointer;
}

.move-top {
  transition: 0.2s;
  cursor: pointer;
}

.footer-right i {
  padding: 0px 10px;
  cursor: pointer;
  font-size: 16px;
  transition: 0.2s;
  color: white;
}

.footer-right i:hover {
  transform: scale(1.2);
}


.main-content {
  border: 1px solid silver;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-size: 16px;
}

.nav-social.big {
  padding-top: 10px;
}

.telegram-mid-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media(max-width:1150px) {
  .mid-main-container {
    flex-direction: column;
  }

  .main-content-wrapper {
    padding-top: 30px;
    width: 90%;
    margin: auto;
  }

  .homeContainer {
    padding-top: 100px;
    display: flex;
    flex-direction: column;
  }

  .features {
    padding-top: 300px;
  }
}


@media(max-width:1100px) {
  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    height: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out;
  }

  .big-card .card-container .single-card {
    width: 90vw;
    margin: auto;

  }

  .small-card-div .small-card {
    width: 43vw;
    margin: 20px auto;

  }

  .lower-layer {
    flex-flow: wrap;
  }

  .text-section h1 {
    font-size: 2em;
  }

  .text-section li {
    font-size: 0.7em;
  }

  .text-section button {
    font-size: 0.7em;
  }
}

@media(max-width:850px) {
  .img-wrapper {
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    padding: 10px 0px;
  }

  .logoContainer.new iframe {
    width: 400px;
    height: 250px;
  }

  .homeContainer {
    display: flex;
    flex-direction: column;
  }

  .homeContent h1 {
    width: 90%;
  }

  .logoContainer.new {
    flex: 1;
    padding: 0px;
    padding-top: 50px;
    height: calc(80vh - 75px);
    margin: auto;
    display: flex;
    align-items: center;

  }

  .logoContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .logoContainer.img {
    padding: 10px;
    width: 330px;
    height: 330px;
  }

}

@media(max-width:700px) {

  .shop-by-link {
    flex-wrap: wrap;
  }

  .shop-by-link li {
    background-color: #363636;
    border-radius: 10px;
    color: white;
    padding: 10px 15px;
    font-size: 0.7em;
  }

  .small-card-div .small-card {
    width: 100%;
    margin: 20px auto;

  }

  .small-card-div {
    margin: 0px 20px;
  }

  .big-card .card-container .single-card {
    width: 100%;
    margin: 20px auto;
  }

  .big-card {
    margin: 0px 10px;
  }

  .airpods-desc {
    flex-direction: column;
    justify-content: center;

  }

  .video-section {
    padding: 0;
    padding-top: 10px;
    margin: 0;
    width: 70%;
  }

  .video-section video {
    width: 100%;
    margin: auto;
  }

  .feature-card h3 {
    font-size: 0.7em;
    margin: 2px;
  }

  .feature-card p {
    font-size: 0.5em;
    margin-top: 0px;
  }

  .feature-card #lottie {
    width: 60x;
    height: 60px;
  }

  .feature-card.span #lottie {
    width: 40px;
    height: 40px;
  }

  .feature-card.span h3 {
    padding-top: 5px;
  }

}

@media(max-width:500px) {
  .popular-category h1 {
    padding-bottom: 15px;
    text-align: center;
    font-size: 2em;
    font-weight: 900;
  }

  .dark-section h1 {
    font-size: 3em;
  }

  .dark-section h3 {
    font-size: 1.3em;
  }

  .text-section h1 {
    font-size: 2em;
  }

  .white-section h1 {
    font-size: 2em;
  }

  .footer-mid {
    display: none;
  }

  .background-container {
    height: 100%;
  }

  .homeContent h1 {
    padding-top: 80px;
    font-size: 48px;
  }

  .header p {
    font-size: 18px;
    margin-top: 5px;

  }

  .nav-social a {
    font-size: 12px;
  }

  .homeContent {
    padding-left: 10px;
  }

  .logoContainer.new iframe {
    width: 350px;
    height: 200px;
  }

  .features {
    padding-top: 0px;
  }

  .homeContainer {
    padding-top: 0px;
  }


}